

@media (max-width: 432px) {
  .root {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 431px) {
  .root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    background-color: #f0f0f0;

    .phone-container {
      width: 100%;
      max-width: 375px;
      height: 812px;
      border: 12px solid black;
      border-radius: 40px;
      overflow: hidden;
      background-color: white;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 45%;
        height: 30px;
        background-color: black;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        z-index: 10000;
      }

      .phone-content {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        box-sizing: border-box;
        position: relative;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          display: none;
        }


        -ms-overflow-style: none;
        scrollbar-width: none;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 30px;
          z-index: 5;
        }
      }
    }
  }
}

.customLink {
  margin-top: 6px;
  color: white;
}

.customLink:hover {
  color: lightblue;
}